import Select from 'react-select';
import React, { useContext, useEffect, useState } from 'react';
import { UserInfokh } from '../../Contexts/UserContext';
import { ApiURL } from '../../App';
import axios from 'axios';
import makeAnimated from 'react-select/animated';
import KhAlert from '../../Components/Alert/KhAlert';
import { TailSpin } from 'react-loader-spinner';

const GenerateBilling = () => {

    const userInfo_Now =useContext(UserInfokh);
    const [alert_,SetAlert]=useState({Activate:false,msg:''});
    const [flag,Setflag]=useState(false);
  
    const [Matirialoptions, setMatirialoptions] = useState([]);
    const [BuyerNamesoptions, setBuyerNamesoptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('refrigerator');
    const animatedComponents = makeAnimated();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption_source, setselectedOption_source] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
                    
          axios.get(ApiURL +'RepositoryMaterials/GetAll',{   
            headers:{
              'Authorization': `bearer ${userInfo_Now.auth.token}`
            }
            }).then((data)=>{
              const Material_holder=[];
              data.data.materials.map((item)=>{
                Material_holder.push({
                  value:item.id,
                  label:item.name,
                  defaultPrice:item.defaultPrice})
                
              });
              setMatirialoptions(Material_holder);
              
              const buyers_holder=[];
              data.data.buyers.map((item)=>{
                  buyers_holder.push({ value:item.id,
                  label:item.name})
                
              })
              
              setBuyerNamesoptions(buyers_holder);
              
            })
            .catch((err)=>{
              SetAlert({Activate:true,msg:'تأكد من الاتصال بالانترنت'});
              })
        
        };
    
        fetchData();
      }, []);

    
      const [formData, setFormData] = useState({
        dealerId: 0,
        dealerName:'',
        coolingRooms: false,
        refrigerator: true,
        externalEnvoices: false,
        daily: false,
        tabali: false,
        plastic: false,
        karasta: false,
        fuel: false,
        cars: false,
        employees:false,
        startDate: null,
        endDate: null
      });

      const handleChange = (name, value,label) => {
        
        if(name==='externalEnvoices')
            {
                setSelectedOption(value);
                setFormData({
                    ...formData,
                    'refrigerator': false,
                    'coolingRooms': false,
                    'externalEnvoices': true,            
                    "daily": false,
                    "tabali": false,
                    "plastic": false,
                    "karasta": false,
                    "fuel": false,
                    "cars": false
                  });
            }
            else if(name==='coolingRooms')
            {
                setSelectedOption(value);
                setFormData({
                    ...formData,
                    'refrigerator': false,
                    'coolingRooms': true,
                    'externalEnvoices': false,            
                    "daily": false,
                    "tabali": false,
                    "plastic": false,
                    "karasta": false,
                    "fuel": false,
                    "cars": false
                  });
            }
            else if(name==='refrigerator')
            {
                setSelectedOption(value);
                setFormData({
                    ...formData,
                    'refrigerator': true,
                    'coolingRooms': false,
                    'externalEnvoices': false,            
                    "daily": false,
                    "tabali": false,
                    "plastic": false,
                    "karasta": false,
                    "fuel": false,
                    "cars": false
                  });
            }
            else
            {
                if(value==="")
                    value=null;
                 if(name==='dealerId')
                 {
                  setselectedOption_source({value,label})
                  setFormData({
                    ...formData,
                    [name]: value,
                    dealerName: label
                  });
                }
                else
                {
                setFormData({
                    ...formData,
                    [name]: value
                  });
                }
            }

       
      };

      const handleSubmit = async (e) => {
        Setflag(true);
        if(!(formData.dealerId<1))
        {
           
          try {
            setIsLoading(true); // Start loading
          const res = await axios.post(ApiURL+'Billing/GeneratePdf', formData, {
            headers: {
              'Accept': 'text/plain',
              'Content-Type': 'application/json',
               'Authorization': `bearer ${userInfo_Now.auth.token}`
            },
            responseType: 'blob', 
          });
         
            // // Step 3: Create a Blob from the response and a URL to open or display it
            // const blob = new Blob([res.data], { type: 'application/pdf' });
            // const url = window.URL.createObjectURL(blob);

            // // Option 1: Open PDF in a new tab
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', 'billing.pdf'); // or any other name you want
            // document.body.appendChild(link);
            // link.click();
            // link.parentNode.removeChild(link);

            // Create a Blob URL for the PDF

            const blob = new Blob([res.data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);

            // Instead of triggering a download, return the URL for WebView
            window.location.href = url;
                  
          
        } catch (error) {      
          SetAlert({Activate:true,msg:error+'تأكد من الاتصال بالانترنت'});
         
        }
        finally {
            setIsLoading(false); // End loading
        }
        }
      };

    return (
        <>
            <KhAlert Kh_Active={alert_.Activate} msg={alert_.msg} func_setactive={SetAlert} />
       <div className='parent-Dmaterial'>
       <div className='child-Dmaterial'>
       
       {!isLoading && <> <div style={{margin:'10px',textAlign:'center'}}>
             <h3>فواتير الإيرادات  </h3></div>  
            <div className="mb-3">
                  <label className="form-label">
                  التاجر
                  </label>
                  <Select
                      id='Select_dealerId_'
                      components={animatedComponents}
                      options={BuyerNamesoptions}
                      value={selectedOption_source}
                      onChange={(selectedOption) => handleChange('dealerId', selectedOption===null?0:selectedOption.value,selectedOption===null?'':selectedOption.label)}
                      isClearable
                      isSearchable
                      placeholder="اختر تاجر ..."
                    />
                 {formData.dealerId<1 &&flag&& ( <p className="validationReg"> *الرجاء اختيار تاجر</p>)}
              </div>

              <div className="mb-3">
                <label className="form-label">
                  من تاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  defaultValue={formData.startDate}
                  onChange={(e)=>{handleChange('startDate',e.target.value)}}
                />
            </div>
            <div className="mb-3">
                <label className="form-label">
                  حتى تاريخ
                </label>
                <input
                  className="form-control"
                  type="date"
                  name="endDate"
                  defaultValue={formData.endDate}
                  onChange={(e)=>{handleChange('endDate',e.target.value)}}
                />
            </div>
            
            <div className="mb-3">
                <div>
                
                <input
                    className='radio-kh'
                    type="radio"
                    name="refrigerator"
                    value="refrigerator"
                    id="refrigerator"
                    checked={selectedOption === 'refrigerator'}
                    onChange={(e)=>{handleChange('refrigerator',e.target.value)}}
                />
                <label htmlFor='refrigerator' className="form-label">
                برادات 
                </label>
                </div>
                <div>
               
                <input
                    className='radio-kh'
                    type="radio"
                    name="externalEnvoices"
                    value="externalEnvoices"
                    id="externalEnvoices"
                    checked={selectedOption === 'externalEnvoices'}
                    onChange={(e)=>{handleChange('externalEnvoices',e.target.value)}}
                />
                 <label htmlFor='externalEnvoices' className="form-label">
                فواتير خارجية 
                </label>
                </div>
                <div>
                
                <input
                    className='radio-kh'
                    type="radio"
                    name="coolingRooms"
                    value="coolingRooms"
                    id="coolingRooms"
                    checked={selectedOption === 'coolingRooms'}
                    onChange={(e)=>{handleChange('coolingRooms',e.target.value)}}
                />
                <label htmlFor='coolingRooms' className="form-label">
                غرف تبريد 
                </label>
                </div>
               
                </div>
                <button className='btn-Add' onClick={()=>{handleSubmit()}} >طباعة</button>
                </>}

                {isLoading && (
            <div style={{textAlign:'center'}}>
                <TailSpin
                    wrapperClass={'loading-kh-new'}
                    height="400"
                    width="50"
                    color="#4fa94d"
                    ariaLabel="loading"
                />
            </div>
        )}
                </div> 
        
       
        </div>
      
        
        </>
    );
}

export default GenerateBilling;
